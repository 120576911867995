import React from 'react';
import { Upload, XIcon } from 'lucide-react';

const ActionButtons = ({ handleUpload, handleCancel, isUploading, newImage }) => {
  return (
    <div className="flex space-x-4">
      <button
        onClick={handleUpload}
        disabled={isUploading || !newImage}
        className={`
          flex-1 flex items-center justify-center px-4 py-3 rounded-lg text-white font-medium
          ${isUploading || !newImage
            ? 'bg-gray-400 cursor-not-allowed'
            : 'bg-blue-500 hover:bg-blue-600 transform hover:-translate-y-1 transition-all duration-200'
          }
        `}
      >
        {isUploading ? (
          <>
            <Upload className="mr-2 h-5 w-5 animate-bounce" />
            Uploading...
          </>
        ) : (
          <>
            <Upload className="mr-2 h-5 w-5" />
            Upload Image
          </>
        )}
      </button>
      <button
        onClick={handleCancel}
        disabled={isUploading}
        className="flex-1 flex items-center justify-center px-4 py-3 rounded-lg text-gray-700 font-medium border border-gray-300 hover:bg-gray-50 transform hover:-translate-y-1 transition-all duration-200"
      >
        <XIcon className="mr-2 h-5 w-5" />
        Cancel
      </button>
    </div>
  );
};

export default ActionButtons;