import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import axios from '../../config/axiosConfig';
import ErrorMessage from '../Message';
import {
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogCancel,
  AlertDialogAction
} from '../ui/alert-dialog';
import EventInterestButton from './EventInterestButton';
import AttendeesList from './AttendeesList';

const EventDetails = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const { state } = useAuth();

  const [eventData, setEventData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');

  useEffect(() => {
    if (!state.isAuthenticated) {
      navigate(`/events/public/${eventId}`);
      return;
    }

    const fetchEvent = async () => {
      try {
        const response = await axios.get(`/events/${eventId}`, {
          headers: {
            Authorization: `Bearer ${state.idToken}`,
          },
        });
        setEventData(response.data);
      } catch (error) {
        console.error('Error fetching event details:', error);
        if (error.response) {
          setErrorMsg(
            `Error (${error.response.status}): ${
              error.response.data?.message || JSON.stringify(error.response.data)
            }`
          );
        } else {
          setErrorMsg(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchEvent();
  }, [state.isAuthenticated, eventId, navigate, state.idToken]);

  const handleDelete = async () => {
    if (!eventData) return;
    try {
      await axios.delete(`/events/${eventData.EventID}`, {
        headers: {
          Authorization: `Bearer ${state.idToken}`,
        },
      });
      navigate('/events');
    } catch (error) {
      console.error('Error deleting event:', error);
      if (error.response) {
        setErrorMsg(
          `Delete failed (${error.response.status}): ${
            error.response.data?.message || JSON.stringify(error.response.data)
          }`
        );
      } else {
        setErrorMsg(`Delete failed: ${error.message}`);
      }
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-100 p-6">
        <div className="max-w-3xl mx-auto bg-white p-6 rounded shadow">
          <p>Loading event details...</p>
        </div>
      </div>
    );
  }

  if (errorMsg) {
    return (
      <div className="min-h-screen bg-gray-100 p-6">
        <div className="max-w-3xl mx-auto bg-white p-6 rounded shadow">
          <ErrorMessage message={errorMsg} />
        </div>
      </div>
    );
  }

  if (!eventData) {
    return (
      <div className="min-h-screen bg-gray-100 p-6">
        <div className="max-w-3xl mx-auto bg-white p-6 rounded shadow">
          <p className="text-red-500">Event not found or an error occurred.</p>
        </div>
      </div>
    );
  }

  const {
    title,
    date,
    location,
    description,
    eventType,
    country,
    website,
    OwnerUserId,
    standardDistances,
    customDistances,
    coverImage
  } = eventData;

  const isOwner = OwnerUserId && state.idTokenPayload?.sub === OwnerUserId;

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="max-w-3xl mx-auto bg-white p-6 rounded shadow">
        {coverImage && (
          <div className="mb-4 w-full relative" style={{ paddingBottom: '56.25%' }}>
            <img
              src={coverImage}
              alt="Event Cover"
              className="absolute inset-0 w-full h-full object-cover rounded"
            />
          </div>
        )}

        <h1 className="text-3xl font-bold text-primary mb-4">{title}</h1>

        <p className="text-gray-700 mb-2">
          <span className="font-semibold">Date:</span> {date || 'N/A'}
        </p>
        <p className="text-gray-700 mb-2">
          <span className="font-semibold">Location:</span> {location || 'N/A'}
        </p>
        <p className="text-gray-700 mb-2">
          <span className="font-semibold">Event Type:</span> {eventType || 'N/A'}
        </p>

        {standardDistances && standardDistances.length > 0 && (
          <p className="text-gray-700 mb-2">
            <span className="font-semibold">Standard Distances:</span>{' '}
            {standardDistances.join(', ')}
          </p>
        )}

        {customDistances && customDistances.length > 0 && (
          <p className="text-gray-700 mb-2">
            <span className="font-semibold">Custom Distances (km):</span>{' '}
            {customDistances.join(', ')}
          </p>
        )}

        <p className="text-gray-700 mb-2">
          <span className="font-semibold">Country:</span> {country || 'N/A'}
        </p>
        {website && (
          <p className="text-gray-700 mb-4">
            <span className="font-semibold">Website:</span>{' '}
            <a
              href={website}
              target="_blank"
              rel="noopener noreferrer"
              className="text-primary hover:underline"
            >
              {website}
            </a>
          </p>
        )}

        <div className="max-h-96 overflow-y-auto mb-4 p-2 border rounded">
          <div
            className="prose max-w-none"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>

        <EventInterestButton eventId={eventId} />
        <AttendeesList eventId={eventId} />

        {isOwner && (
          <div className="mt-6">
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <button
                  className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-red-600
                  border border-transparent rounded-md shadow-sm hover:bg-red-700 focus:outline-none
                  focus:ring-2 focus:ring-red-500 focus:ring-offset-2 transition-colors"
                >
                  Delete Event
                </button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Delete Event</AlertDialogTitle>
                  <AlertDialogDescription>
                    Are you sure you want to delete this event? This action cannot be undone.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction onClick={handleDelete}>
                    Delete
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventDetails;