import React, { useState, useRef, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import axios from '../../config/axiosConfig';
import {
  ChevronLeft,
  ChevronRight,
  ChevronDown,
  LayoutList,
  ImageIcon,
  FileEdit
} from 'lucide-react';

/**
 * EventAdminLayout – a layout component that replicates the structure
 * of the RideAdmin "AdminLayout," but for events. It shows a sidebar
 * with links to subpages (e.g., cover image, event details).
 */

const EventAdminLayout = ({ children, title }) => {
  const { eventId } = useParams();
  const location = useLocation();
  const navItemRefs = useRef({});
  const [activeItemId, setActiveItemId] = useState(null);

  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [expandedCategories, setExpandedCategories] = useState(['event-admin']);

  // Fetch the event to display name in the sidebar header
  const {
    data: eventData,
    isLoading: eventLoading,
    isError: eventError
  } = useQuery({
    queryKey: ['eventDetailsForSidebar', eventId],
    queryFn: () => axios.get(`/events/${eventId}`).then(res => res.data),
    enabled: !!eventId
  });

  // The sidebar has just one category for now: "Event Admin"
  const categories = [
    {
      id: 'event-admin',
      label: 'Event Admin',
      icon: <LayoutList className="h-5 w-5" />,
      items: [
        {
          id: 'details',
          label: 'Edit Details',
          icon: <FileEdit className="h-4 w-4" />,
          link: `/events/edit/${eventId}/details`,
        },
        {
          id: 'cover-image',
          label: 'Edit Cover Image',
          icon: <ImageIcon className="h-4 w-4" />,
          link: `/events/edit/${eventId}/cover-image`,
        },
      ]
    }
  ];

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const toggleCategory = (catId) => {
    if (isSidebarCollapsed) {
      // If collapsed, expand first then open
      setIsSidebarCollapsed(false);
      setExpandedCategories([catId]);
    } else {
      if (expandedCategories.includes(catId)) {
        setExpandedCategories(expandedCategories.filter((id) => id !== catId));
      } else {
        setExpandedCategories([...expandedCategories, catId]);
      }
    }
  };

  const isLinkActive = (itemLink) => {
    if (!itemLink || !location.pathname) return false;
    return location.pathname.startsWith(itemLink);
  };

  useEffect(() => {
    if (activeItemId && navItemRefs.current[activeItemId]) {
      navItemRefs.current[activeItemId].scrollIntoView({
        block: 'nearest',
        behavior: 'smooth',
      });
    }
  }, [activeItemId, location.pathname]);

  const renderNavItem = (item) => {
    const active = isLinkActive(item.link);
    if (active && item.id !== activeItemId) {
      setActiveItemId(item.id);
    }

    return (
      <Link
        key={item.id}
        to={item.link}
        ref={(el) => (navItemRefs.current[item.id] = el)}
        className={`flex items-center gap-2 px-4 py-2 text-sm rounded-md transition-all
          ${active ? 'bg-blue-100 text-blue-700 font-semibold' : 'text-gray-700 hover:bg-blue-50'}
        `}
      >
        {item.icon}
        {!isSidebarCollapsed && (
          <span className="whitespace-nowrap overflow-hidden text-ellipsis">
            {item.label}
          </span>
        )}
      </Link>
    );
  };

  const renderCategory = (cat) => {
    const isExpanded = expandedCategories.includes(cat.id);
    return (
      <div key={cat.id} className="flex flex-col">
        <button
          onClick={() => toggleCategory(cat.id)}
          className="flex items-center justify-between px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-md transition-all"
        >
          <div className="flex items-center gap-2">
            {cat.icon}
            {!isSidebarCollapsed && (
              <span className="font-semibold whitespace-nowrap overflow-hidden text-ellipsis">
                {cat.label}
              </span>
            )}
          </div>
          {!isSidebarCollapsed && (
            <div className="text-gray-400">
              {isExpanded ? <ChevronDown className="h-4 w-4" /> : <ChevronRight className="h-4 w-4" />}
            </div>
          )}
        </button>
        {isExpanded && !isSidebarCollapsed && (
          <div className="pl-6 flex flex-col space-y-1">
            {cat.items.map(renderNavItem)}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="flex min-h-screen w-full bg-gray-100 text-gray-800">
      {/* Sidebar */}
      <div
        className={`
          flex flex-col bg-white border-r border-gray-200
          transition-all duration-300
          ${isSidebarCollapsed ? 'w-16' : 'w-64'}
        `}
      >
        {/* Sidebar header */}
        <div className="flex items-center justify-between h-16 px-4 bg-gray-50 border-b border-gray-200">
          {!isSidebarCollapsed && (
            <div className="flex flex-col">
              <div className="flex items-center gap-2 whitespace-nowrap">
                <LayoutList className="h-5 w-5 text-blue-600" />
                <span className="font-bold text-gray-600 overflow-hidden text-ellipsis">
                  Event Admin
                </span>
              </div>
              {/* Show event name if available */}
              {!eventLoading && !eventError && eventData && (
                <div className="text-xs text-gray-500 mt-1 truncate">
                  Editing: {eventData.title || 'No Title'}
                </div>
              )}
            </div>
          )}
          <button
            onClick={toggleSidebar}
            className="text-gray-500 hover:text-gray-700"
            aria-label="Toggle sidebar"
          >
            {isSidebarCollapsed ? (
              <ChevronRight className="h-5 w-5" />
            ) : (
              <ChevronLeft className="h-5 w-5" />
            )}
          </button>
        </div>

        {/* Nav items */}
        <nav className="flex-1 p-2 space-y-2">
          {categories.map(renderCategory)}
        </nav>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-6 space-y-4">
        {title && <h1 className="text-2xl font-bold mb-6">{title}</h1>}
        {children}
      </div>
    </div>
  );
};

export default EventAdminLayout;