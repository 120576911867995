import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../config/axiosConfig';
import { useAuth } from '../../context/AuthContext';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { statesAndCities } from '../../config/statesAndCities';

/**
 * EditEventDetails
 *
 * Contains the form logic to update the event details.
 * This was migrated from the old "EditEventPage" so that it's
 * now only handling the form, not the sidebar layout.
 */

const EditEventDetails = () => {
  const { eventId } = useParams();
  const navigate = useNavigate();
  const { state } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState('');

  // Basic event data
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [location, setLocation] = useState('');
  const [date, setDate] = useState('');
  const [country, setCountry] = useState('India');
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [eventType, setEventType] = useState('');
  const [website, setWebsite] = useState('');

  // Distances
  const runningDistancesList = ['5K', '10K', '21K (Half Marathon)', '42K (Marathon)'];
  const triathlonDistancesList = ['Sprint', 'Olympic', 'Half Ironman', 'Full Ironman'];
  const [runningDistances, setRunningDistances] = useState([]);
  const [triathlonDistances, setTriathlonDistances] = useState([]);
  const [cyclingType, setCyclingType] = useState('');
  const [customDistances, setCustomDistances] = useState([]);

  // Quill modules
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean'],
    ],
  };

  useEffect(() => {
    if (!state.isAuthenticated) {
      navigate(`/login?redirect=/events/edit/${eventId}/details`);
      return;
    }

    const fetchEvent = async () => {
      try {
        const response = await axios.get(`/events/${eventId}`, {
          headers: {
            Authorization: `Bearer ${state.idToken}`,
          },
        });

        const evt = response.data;
        // Check ownership
        if (evt.OwnerUserId !== state.idTokenPayload?.sub) {
          setErrorMsg('You are not the owner of this event.');
          setIsLoading(false);
          return;
        }

        setTitle(evt.title || '');
        setDescription(evt.description || '');
        setLocation(evt.location || '');
        setDate(evt.date || '');
        setCountry(evt.country || 'India');
        setSelectedState(evt.state || '');
        setSelectedCity(evt.city || '');
        setEventType(evt.eventType || '');
        setWebsite(evt.website || '');
        setRunningDistances(evt.runningDistances || []);
        setTriathlonDistances(evt.triathlonDistances || []);
        setCyclingType(evt.cyclingType || '');
        setCustomDistances(evt.customDistances || []);
      } catch (error) {
        console.error('Error fetching event:', error);
        if (error.response) {
          setErrorMsg(
            `Error (${error.response.status}): ${
              error.response.data?.message || JSON.stringify(error.response.data)
            }`
          );
        } else {
          setErrorMsg(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchEvent();
  }, [eventId, navigate, state.idToken, state.idTokenPayload?.sub, state.isAuthenticated]);

  const handleRunningDistanceChange = (dist) => {
    setRunningDistances((prev) =>
      prev.includes(dist) ? prev.filter((d) => d !== dist) : [...prev, dist]
    );
  };

  const handleTriathlonDistanceChange = (dist) => {
    setTriathlonDistances((prev) =>
      prev.includes(dist) ? prev.filter((d) => d !== dist) : [...prev, dist]
    );
  };

  const handleAddCustomDistance = () => {
    setCustomDistances((prev) => [...prev, '']);
  };

  const handleRemoveCustomDistance = (index) => {
    setCustomDistances((prev) => prev.filter((_, i) => i !== index));
  };

  const handleCustomDistanceChange = (index, value) => {
    setCustomDistances((prev) => {
      const updated = [...prev];
      updated[index] = value;
      return updated;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMsg('');

    try {
      const body = {
        title,
        description,
        location,
        date,
        country,
        state: selectedState,
        city: selectedCity,
        eventType,
        website,
        runningDistances,
        triathlonDistances,
        cyclingType,
        customDistances: customDistances
          .map((cd) => parseFloat(cd))
          .filter((num) => !isNaN(num) && num > 0),
      };

      await axios.put(`/events/${eventId}`, body, {
        headers: {
          Authorization: `Bearer ${state.idToken}`,
        },
      });

      // You might want to navigate back to /events/my or stay here
      // For now let's remain on the page so user can confirm changes
      // navigate('/events/my');
      alert("Event details updated successfully!");
    } catch (err) {
      console.error('Error updating event:', err);
      if (err.response) {
        setErrorMsg(
          `Update failed (${err.response.status}): ${
            err.response.data?.message || JSON.stringify(err.response.data)
          }`
        );
      } else {
        setErrorMsg(`Update failed: ${err.message}`);
      }
    }
  };

  if (isLoading) {
    return (
      <div className="bg-white p-6 rounded shadow">
        <p>Loading event data...</p>
      </div>
    );
  }

  if (errorMsg) {
    return (
      <div className="bg-white p-6 rounded shadow">
        <p className="text-red-600">{errorMsg}</p>
      </div>
    );
  }

  return (
    <div className="bg-white p-6 rounded shadow space-y-6">
      <h2 className="text-xl font-semibold text-gray-800">Edit Event Details</h2>

      <form onSubmit={handleSubmit} className="space-y-4">
        {/* Title */}
        <div>
          <label className="block text-gray-700 mb-1" htmlFor="title">
            Event Title
          </label>
          <input
            type="text"
            id="title"
            required
            className="w-full px-3 py-2 border rounded"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>

        {/* Description with Quill */}
        <div>
          <label className="block text-gray-700 mb-1" htmlFor="description">
            Description
          </label>
          <ReactQuill
            id="description"
            value={description}
            onChange={setDescription}
            modules={modules}
            theme="snow"
            className="mb-2"
          />
        </div>

        {/* Location */}
        <div>
          <label className="block text-gray-700 mb-1" htmlFor="location">
            Location (Address/Venue)
          </label>
          <input
            type="text"
            id="location"
            className="w-full px-3 py-2 border rounded"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
          />
        </div>

        {/* Date */}
        <div>
          <label className="block text-gray-700 mb-1" htmlFor="date">
            Date
          </label>
          <input
            type="date"
            id="date"
            className="w-full px-3 py-2 border rounded"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </div>

        {/* State */}
        <div>
          <label className="block text-gray-700 mb-1" htmlFor="state">
            State
          </label>
          <select
            id="state"
            className="w-full px-3 py-2 border rounded"
            value={selectedState}
            onChange={(e) => {
              setSelectedState(e.target.value);
              setSelectedCity('');
            }}
          >
            <option value="">-- Select a State --</option>
            {Object.keys(statesAndCities).map((stateName) => (
              <option key={stateName} value={stateName}>
                {stateName}
              </option>
            ))}
          </select>
        </div>

        {/* City */}
        {selectedState && (
          <div>
            <label className="block text-gray-700 mb-1" htmlFor="city">
              City
            </label>
            <select
              id="city"
              className="w-full px-3 py-2 border rounded"
              value={selectedCity}
              onChange={(e) => setSelectedCity(e.target.value)}
            >
              <option value="">-- Select a City --</option>
              {(statesAndCities[selectedState] || []).map((city) => (
                <option key={city} value={city}>
                  {city}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Event Type */}
        <div>
          <label className="block text-gray-700 mb-1" htmlFor="eventType">
            Event Type
          </label>
          <select
            id="eventType"
            required
            className="w-full px-3 py-2 border rounded"
            value={eventType}
            onChange={(e) => {
              setEventType(e.target.value);
              setRunningDistances([]);
              setTriathlonDistances([]);
              setCyclingType('');
              setCustomDistances([]);
            }}
          >
            <option value="">-- Select an event type --</option>
            <option value="Running">Running</option>
            <option value="Cycling">Cycling</option>
            <option value="Triathlon">Triathlon</option>
            <option value="Other">Other</option>
          </select>
        </div>

        {/* Running Distances */}
        {eventType === 'Running' && (
          <div>
            <p className="block text-gray-700 mb-1">
              Standard Running Distances (select all that apply):
            </p>
            {runningDistancesList.map((dist) => (
              <label key={dist} className="flex items-center mb-1">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={runningDistances.includes(dist)}
                  onChange={() => handleRunningDistanceChange(dist)}
                />
                {dist}
              </label>
            ))}
          </div>
        )}

        {/* Triathlon Distances */}
        {eventType === 'Triathlon' && (
          <div>
            <p className="block text-gray-700 mb-1">
              Standard Triathlon Distances (select all that apply):
            </p>
            {triathlonDistancesList.map((dist) => (
              <label key={dist} className="flex items-center mb-1">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={triathlonDistances.includes(dist)}
                  onChange={() => handleTriathlonDistanceChange(dist)}
                />
                {dist}
              </label>
            ))}
          </div>
        )}

        {/* Cycling Type */}
        {eventType === 'Cycling' && (
          <div>
            <p className="block text-gray-700 mb-1">Cycling Type:</p>
            <label className="flex items-center mb-1">
              <input
                type="radio"
                name="cyclingType"
                value="Race"
                className="mr-2"
                checked={cyclingType === 'Race'}
                onChange={(e) => setCyclingType(e.target.value)}
              />
              Race
            </label>
            <label className="flex items-center mb-1">
              <input
                type="radio"
                name="cyclingType"
                value="Non-race"
                className="mr-2"
                checked={cyclingType === 'Non-race'}
                onChange={(e) => setCyclingType(e.target.value)}
              />
              Non-race
            </label>
          </div>
        )}

        {/* Custom Distances */}
        <div>
          <p className="block text-gray-700 mb-1">Custom Distances (km)</p>
          {customDistances.map((cd, index) => (
            <div key={index} className="flex items-center space-x-2 mb-2">
              <input
                type="number"
                className="w-24 px-3 py-2 border rounded"
                value={cd}
                onChange={(e) => handleCustomDistanceChange(index, e.target.value)}
              />
              <button
                type="button"
                onClick={() => handleRemoveCustomDistance(index)}
                className="px-3 py-1 bg-red-600 text-white rounded hover:bg-red-700"
              >
                Remove
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={handleAddCustomDistance}
            className="bg-blue-600 text-white px-3 py-1 rounded hover:bg-blue-700"
          >
            + Add Custom Distance
          </button>
        </div>

        {/* Website */}
        <div>
          <label className="block text-gray-700 mb-1" htmlFor="website">
            Organizer&apos;s Website URL
          </label>
          <input
            type="url"
            id="website"
            className="w-full px-3 py-2 border rounded"
            value={website}
            onChange={(e) => setWebsite(e.target.value)}
          />
        </div>

        {/* Submit */}
        <button
          type="submit"
          className="bg-blue-600 text-white px-4 py-2 rounded hover:bg-blue-700 transition"
        >
          Update Event
        </button>
      </form>
    </div>
  );
};

export default EditEventDetails;