import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import axios from '../../config/axiosConfig';

import ImagePreview from '../Events/CoverImage/ImagePreview';
import ImageUploader from '../Events/CoverImage/ImageUploader';
import ImagePreviewWithInfo from '../Events/CoverImage/ImagePreviewWithInfo';
import ActionButtons from '../Events/CoverImage/ActionButtons';

/**
 * EditEventCoverImage
 *
 * A sub-route that allows the user to edit the event's cover image,
 * replicating the approach from "EventCoverImageManager" but extracted
 * into its own component for clarity.
 */

const EditEventCoverImage = () => {
  const { eventId } = useParams();
  const queryClient = useQueryClient();
  const [editing, setEditing] = useState(false);
  const [newImage, setNewImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [imageInfo, setImageInfo] = useState(null);
  const [isZoomed, setIsZoomed] = useState(false);
  const [dragCount, setDragCount] = useState(0);

  // Query the event details so we can display any existing coverImage
  const { data: eventData } = useQuery({
    queryKey: ['eventDetails', eventId],
    queryFn: () => axios.get(`/events/${eventId}`).then(res => res.data),
    enabled: !!eventId
  });

  useEffect(() => {
    if (eventData?.coverImage) {
      setPreviewUrl(eventData.coverImage);
    }
  }, [eventData]);

  // Mutation for removing existing cover image
  const removeMutation = useMutation({
    mutationFn: async () => {
      return axios.patch(`/events/${eventId}`, { coverImage: '' });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['eventDetails', eventId]);
      setPreviewUrl('');
      setSuccess('Cover image removed successfully');
      setTimeout(() => setSuccess(''), 3000);
    },
    onError: () => {
      setError('Failed to remove cover image');
      setTimeout(() => setError(''), 3000);
    }
  });

  const handleRemoveImage = () => {
    if (window.confirm('Are you sure you want to remove the cover image?')) {
      removeMutation.mutate();
    }
  };

  useEffect(() => {
    if (newImage) {
      const img = new Image();
      img.src = previewUrl;
      img.onload = () => {
        setImageInfo({
          width: img.width,
          height: img.height,
          aspectRatio: (img.width / img.height).toFixed(2),
          size: (newImage.size / (1024 * 1024)).toFixed(2)
        });
      };
    }
  }, [newImage, previewUrl]);

  // Handle the dropping of a file
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setError('Image size must be less than 5MB');
        return;
      }
      setNewImage(file);
      setPreviewUrl(URL.createObjectURL(file));
      setEditing(true);
      setError('');
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.webp']
    },
    maxSize: 5 * 1024 * 1024,
    multiple: false
  });

  // Actually upload the new image
  const handleUpload = async () => {
    if (!newImage) return;
    setIsUploading(true);
    setError('');

    try {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64Image = reader.result.split(',')[1];

        // Simulate progress
        const interval = setInterval(() => {
          setUploadProgress(prev => {
            if (prev >= 90) {
              clearInterval(interval);
              return 90;
            }
            return prev + 10;
          });
        }, 200);

        try {
          // Patch the event with the image data
          const response = await axios.patch(`/events/${eventId}`, {
            imageBase64: base64Image
          });

          setUploadProgress(100);
          setTimeout(() => {
            setEditing(false);
            setNewImage(null);
            setIsUploading(false);
            setUploadProgress(0);
            setPreviewUrl(response.data.coverImageUrl || '');
            setSuccess('Cover image updated successfully');
            queryClient.invalidateQueries(['eventDetails', eventId]);
            setTimeout(() => setSuccess(''), 3000);
          }, 500);
        } catch (error) {
          setError('Failed to upload image. Please try again.');
          console.error('Error uploading image:', error);
          clearInterval(interval);
          setUploadProgress(0);
          setIsUploading(false);
        }
      };
      reader.readAsDataURL(newImage);
    } catch (error) {
      setError('Failed to process image. Please try again.');
      setIsUploading(false);
    }
  };

  // Cancel new upload
  const handleCancel = () => {
    setEditing(false);
    setNewImage(null);
    setPreviewUrl(eventData?.coverImage || '');
    setError('');
    setImageInfo(null);
  };

  // track drag events
  const handleDragEnter = () => {
    setDragCount(prev => prev + 1);
  };

  const handleDragLeave = () => {
    setDragCount(prev => prev - 1);
  };

  return (
    <div className="bg-white p-6 rounded shadow space-y-6">
      <h2 className="text-xl font-semibold text-gray-800">Edit Cover Image</h2>

      {success && (
        <div className="mb-4 p-4 bg-green-50 border-l-4 border-green-500 text-green-700">
          {success}
        </div>
      )}
      {error && (
        <div className="mb-4 p-4 bg-red-50 border-l-4 border-red-500 text-red-700">
          {error}
        </div>
      )}
      <div className="space-y-6">
        {!editing ? (
          <ImagePreview
            previewUrl={previewUrl}
            isZoomed={isZoomed}
            setIsZoomed={setIsZoomed}
            handleRemoveImage={handleRemoveImage}
            setEditing={setEditing}
          />
        ) : (
          <div className="space-y-4">
            <ImageUploader
              getRootProps={getRootProps}
              getInputProps={getInputProps}
              isDragActive={isDragActive}
              dragCount={dragCount}
              handleDragEnter={handleDragEnter}
              handleDragLeave={handleDragLeave}
            />

            {previewUrl && (
              <>
                <ImagePreviewWithInfo
                  previewUrl={previewUrl}
                  imageInfo={imageInfo}
                  isUploading={isUploading}
                  uploadProgress={uploadProgress}
                  newImage={newImage}
                />
                <ActionButtons
                  handleUpload={handleUpload}
                  handleCancel={handleCancel}
                  isUploading={isUploading}
                  newImage={newImage}
                />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EditEventCoverImage;