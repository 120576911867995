import React from 'react';
import { Outlet, Navigate, useParams } from 'react-router-dom';
import EventAdminLayout from './EventAdminLayout';

/**
 * EventEditPage
 *
 * This component acts as the parent route for editing an event, providing:
 * - The EventAdminLayout (sidebar).
 * - Nested routes that point to:
 *    - /events/edit/:eventId/details  => EditEventDetails
 *    - /events/edit/:eventId/cover-image => EditEventCoverImage
 *
 * The user lands on "/events/edit/:eventId" which we can redirect to "details" by default.
 */

const EventEditPage = () => {
  const { eventId } = useParams();

  // If there's no sub-route, redirect to "details"
  // But if you prefer to show something else, you can remove this and do it in App.js with an <IndexRoute />
  if (!window.location.pathname.includes('cover-image') &&
      !window.location.pathname.includes('details')) {
    return <Navigate to={`/events/edit/${eventId}/details`} replace />;
  }

  return (
    <EventAdminLayout title="Manage Event">
      <Outlet />
    </EventAdminLayout>
  );
};

export default EventEditPage;